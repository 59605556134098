import React from 'react'
import styled from 'styled-components'
import {
  PageTitle,
  PageTitle2,
  PageTitle3,
  PageParagraph,
} from '../components/PageTitle'
import { Photo } from '../components/PhotoCarousel'
import { Box, Button, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground } from '../components/Wrapper'
import Default from '../components/Layouts/Default'
import { CustomerCardGroup } from '../components/CustomerCard'
import { Helmet } from 'react-helmet'

import LiveWithCustomer from './images/liveWithTheCustomer.svg'
import OutcomesOverProducts from './images/outcomesOverProducts.svg'
import FiveYearHorizons from './images/5yearHorizons.svg'
import UnconditionalTransparency from './images/unconditionalTransparency.svg'
import EarnYourJob from './images/earnYourJob.svg'

export default class AboutPage extends React.Component {
  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Our Company</title>
          <meta
            name="description"
            content="We’re a rapidly growing, profitable startup backed by some of the best VCs (Index, Greylock, BoldStart, ENIAC) and Angel Investors (founders at Yammer, Eventbrite, FlatironHealth, Clover Health, SeatMe, Angelpad)."
          />
        </Helmet>
        <WrapperBackground
          color={colors.trulyDark}
          background="bridge"
          gradientType="darkLessTransparent">
          <Section
            flexDirection="column"
            py="8rem"
            background={colors.trulyDark}>
            <Box width={[1, 1]}>
              <PageTitle color={colors.white} mb="16pt">
                Transforming the way enterprises engage their customers.
              </PageTitle>
              <PageParagraph color={colors.white}>
                We’re a rapidly growing, profitable startup backed by some of
                the best VCs (Index, Greylock, BoldStart, ENIAC) and Angel
                Investors (founders at Yammer, Eventbrite, FlatironHealth,
                Clover Health, SeatMe, Angelpad). Our customers include Square,
                Zocdoc, Signpost and other leading sales organizations.
              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground background="sf" gradientType="greenToLightGreen">
          <Section center flexDirection={['column', 'row']} my="2rem">
            <Box center width={[1, 1 / 2]} pb="2rem">
              <Photo photo="simon" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
                A Team Of Founders
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                Most of us have either been founders or ‘first hire’ at startups
                that have been acquired or gone public. We know and love
                building products that create big companies.
              </PageParagraph>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://truly.pinpointhq.com">
                <Button
                  size="large"
                  type="outline"
                  color={colors.white}
                  round
                  value="Join Us"
                />
              </a>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground
          color={colors.trulyDark}
          background="managedByQ"
          gradientType="light">
          <Section
            background={colors.trulyDark}
            flexDirection="column"
            mt="1rem"
            mb="2rem"
            maxWidth="100%">
            <Box mb="1rem">
              <PageTitle2 color={colors.trulyDark} mb="2rem">
                Our Customers
              </PageTitle2>
              <PageParagraph color={colors.trulyDark}>
                These are the sales teams that trust Truly:
              </PageParagraph>
            </Box>
            <CustomerCardGroup />
          </Section>
        </WrapperBackground>

        <Values>
          <Section flexDirection="column">
            <PageTitle color={colors.white} mt="32pt">
              What We Believe
            </PageTitle>
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box
              width={[1 / 3, 1 / 4]}
              pr={['0pt', '32pt']}
              pb={['32pt', '0pt']}>
              <WidthImg src={LiveWithCustomer} alt="Live With The Customer" />
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white}>
                Live with the Customer
              </PageTitle3>
              <Large center color={colors.white}>
                We are only successful when our customers are successful. We go
                above and beyond to learn about their businesses and understand
                their pain points.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Focus on Outcomes Over Products
              </PageTitle3>
              <Large center color={colors.white}>
                We commit ourselves to achieving successful outcomes for our
                customers, whether it has to do with our products or not.
              </Large>
            </Box>
            <Box
              width={[1 / 3, 1 / 4]}
              pl={['0pt', '32pt']}
              pt={['32pt', '0pt']}>
              <WidthImg
                src={OutcomesOverProducts}
                alt="Outcomes Over Products"
              />
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box
              width={[1 / 3, 1 / 4]}
              pr={['0pt', '32pt']}
              pb={['32pt', '0pt']}>
              <WidthImg src={FiveYearHorizons} alt="Five Year Horizons" />
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                We think in 5 year horizons
              </PageTitle3>
              <Large center color={colors.white}>
                We build things the right (and sometimes hard) way, optimized
                for long term partnerships and products.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Unconditional Transparency
              </PageTitle3>
              <Large center color={colors.white}>
                We believe that everyone deserves access to 100% information. We
                incorporate radical transparency into everything that we do.
              </Large>
            </Box>
            <Box
              width={[1 / 3, 1 / 4]}
              pr={['0pt', '32pt']}
              pt={['32pt', '0pt']}>
              <WidthImg
                src={UnconditionalTransparency}
                alt="Unconditional Transparency"
              />
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']} pb="64pt">
            <Box
              width={[1 / 3, 1 / 4]}
              pr={['0pt', '32pt']}
              pb={['32pt', '0pt']}>
              <WidthImg src={EarnYourJob} alt="Earn Your Job Every Day" />
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Earn Your Job Everyday
              </PageTitle3>
              <Large center color={colors.white}>
                What got you here never gets you there. We come to work everyday
                focused on impact rather than projects.
              </Large>
            </Box>
          </Section>
        </Values>

        <WrapperBackground
          color={colors.trulyDark}
          background="sf"
          gradientType="purpleToGreen">
          <Section
            flexDirection="column"
            py={['6rem', '7rem']}
            background={colors.trulyDark}>
            <Box width={[1, 1]}>
              <PageTitle color={colors.white} mb="16pt">
                Our Mission Is To Help Our Customers Deliver Incredible Sales
                Experiences At Scale
              </PageTitle>
              <Title3 as="h2" color={colors.white} bold mb="1.5rem" center>
                Let's Get There Together
              </Title3>
            </Box>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://truly.pinpointhq.com">
              <Button
                type="outline"
                color={colors.white}
                size="large"
                round
                value="Join Us"
              />
            </a>
          </Section>
        </WrapperBackground>
      </Default>
    )
  }
}

const WidthImg = styled.img`
  width: 100%;
`

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`
