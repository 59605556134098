import React from 'react'
import styled, { keyframes } from 'styled-components'

import Simon from './photos/Simon.jpg'
import Clay from './photos/Clay.jpg'
import Anya from './photos/Anya.jpg'
import John from './photos/John.jpg'

export default class Photo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      index: 0
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        index: this.state.index+1
      });
    }, 4000)
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getOffset = (index) => {
    const length = 6;
    const timerValue = this.state.index;
    const effective = (timerValue + index) % length;
    return `translateZ(${16 * effective}pt)`
  }

  getOpacity = (index) => {
    const length = 6;
    const timerValue = this.state.index;
    const effective = (timerValue + index) % length;
    return effective < 3 ? '1': '0';
  }
 
  render() {
    return (
      <Scene>
        <div>
        <PhotoFrameContainer>
          <PhotoFrame transform={this.getOffset(1)} opacity={this.getOpacity(0)} src={Simon}/>
          <PhotoFrame transform={this.getOffset(2)} opacity={this.getOpacity(1)} src={Clay}/>
          <PhotoFrame transform={this.getOffset(3)} opacity={this.getOpacity(2)} src={John}/>
          <PhotoFrame transform={this.getOffset(4)} opacity={this.getOpacity(3)} src={Anya}/>
          <PhotoFrame transform={this.getOffset(5)} opacity={this.getOpacity(4)} src={Simon}/>
          <PhotoFrame transform={this.getOffset(6)} opacity={this.getOpacity(5)} src={John}/>
        </PhotoFrameContainer>
        </div>
      </Scene>
  )
    
  }
}

const rotate = keyframes`
  0% {
    transform: rotateX(20deg) rotateY(0deg);
  }

  100% {
    transform: rotateX(20deg) rotateY(0deg);
  }
`;

const Scene = styled.div`
  perspective: 600px;
  height: 260px;
  width: 260px;
  margin: 32pt auto;
`

const PhotoFrameContainer = styled.div`
  position: relative;
  transform-style: preserve-3d;
  animation: ${rotate} 10s linear infinite;
  height: 260px;
  width: 260px;
`

const PhotoFrame = styled.img`
  ${props => `transform: ${props.transform};`}
  ${props => `opacity: ${props.opacity};`}
  border-radius: 8pt;
  left: 0;
  width: 260px;
  height: 260px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0pt 4pt 32pt rgba(0,0,0,0.3);
  position: absolute;
  transition: all 2s;
`
